/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./style.css";
import Navbar from "./Components/Navbar";
import Menu from "./Components/Menu";
import Hero from "./Components/Hero";
import WhoWeAre from "./Components/WhoWeAre";
import Journey from "./Components/Journey";
import Services from "./Components/Services";
import TailoredPrograms from "./Components/TailoredPrograms";
import Ambassador from "./Components/Ambassador";
import Stories from "./Components/Stories";
import OurTeam from "./Components/OurTeam";
import MediumArticles from "./Components/MediumArticles";
import JoinTeam from "./Components/JoinTeam";
import Footer from "./Components/Footer";
import { colors } from "./Theme/colors";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  useLocation,
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  const [scrollPosition, setScrollPosition] = useState(0);
  const [menuShown, setMenuShown] = useState(false);
  const [reset, setReset] = useState(false);

  const openMenu = () => {
    setMenuShown(true);
    setScrollPosition(window.scrollY);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const closeMenu = () => {
    setMenuShown(false);
    setTimeout(() => {
      window.scrollTo({ top: scrollPosition });
    }, 10);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location?.search);
    const scrollTo = searchParams.get("section") ?? "";
    if (scrollTo) {
      window?.scrollTo({
        top: document?.getElementById(scrollTo)?.offsetTop + 20,
      });
    }
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location?.search);
    const section = searchParams.get("section") ?? "";
    if (section && !reset) {
      const handleScroll = () => {
        navigate("/");
        window.removeEventListener("scroll", handleScroll);
      };
      setReset(true);
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [navigate]);

  useEffect(() => {
    document.getElementById("root").style.background = colors.darkGreen;
  }, []);

  useEffect(() => {
    AOS.init({
      once: false,
    });
  }, []);

  return (
    <div className="app-wrapper">
      {menuShown ? (
        <Menu closeMenu={closeMenu} />
      ) : (
        <>
          <Navbar openMenu={openMenu} />
          <Hero />
          <Stories />
          <WhoWeAre />
          <Journey />
          <Services />
          <TailoredPrograms />
          <Ambassador />
          <OurTeam />
          <MediumArticles />
          <JoinTeam />
          <Footer />
        </>
      )}
    </div>
  );
}

function Main() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Main;
