import React from "react";
import "./OneService.css";

const OneService = ({ icon, title, text }) => {
  return (
    <div
      className="one-service-item"
      data-aos="animation-scale-y"
      data-aos-delay="400"
    >
      <img
        className="one-service-icon"
        src={icon}
        alt="Icon"
        draggable="false"
        loading="lazy"
      />
      <div className="one-service-content">
        <p className="one-service-title">{title}</p>
        <p className="one-service-text">{text}</p>
      </div>
    </div>
  );
};

export default OneService;
