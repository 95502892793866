import React, { useEffect, useState } from "react";
import "./TailoredPrograms.css";
import HeadstartIcon from "../../assets/images/HeadStartMain.webp";
import ProductLaunchpadIcon from "../../assets/images/ProductLaunchpadMain.webp";
import TeamBoostIcon from "../../assets/images/TeamBoostMain.webp";
import HeadstartIconOne from "../../assets/icons/HeadStartOne.svg";
import HeadstartIconTwo from "../../assets/icons/HeadStartTwo.svg";
import HeadstartIconThree from "../../assets/icons/HeadStartThree.svg";
import HeadstartIconFour from "../../assets/icons/HeadStartFour.svg";
import HeadstartIconOneMobile from "../../assets/icons/HeadstartIconOneMobile.svg";
import HeadstartIconTwoMobile from "../../assets/icons/HeadstartIconTwoMobile.svg";
import HeadstartIconThreeMobile from "../../assets/icons/HeadstartIconThreeMobile.svg";
import HeadstartIconFourMobile from "../../assets/icons/HeadstartIconFourMobile.svg";
import ProductLaunchpadIconOne from "../../assets/icons/HeadStartOne.svg";
import ProductLaunchpadIconTwo from "../../assets/icons/ProductLaunchpadTwo.svg";
import ProductLaunchpadIconThree from "../../assets/icons/ProductLaunchpadThree.svg";
import ProductLaunchpadIconOneMobile from "../../assets/icons/HeadstartIconOneMobile.svg";
import ProductLaunchpadIconTwoMobile from "../../assets/icons/ProductLaunchpadIconTwoMobile.svg";
import ProductLaunchpadIconThreeMobile from "../../assets/icons/ProductLaunchpadIconThreeMobile.svg";
import TeamBoostIconOne from "../../assets/icons/HeadStartOne.svg";
import TeamBoostIconTwo from "../../assets/icons/TeamBoostTwo.svg";
import TeamBoostIconThree from "../../assets/icons/TeamBoostThree.svg";
import TeamBoostIconOneMobile from "../../assets/icons/HeadstartIconOneMobile.svg";
import TeamBoostIconTwoMobile from "../../assets/icons/TeamBoostIconTwoMobile.svg";
import TeamBoostIconThreeMobile from "../../assets/icons/TeamBoostIconThreeMobile.svg";
import { strings } from "../../Translations/strings";
import ArrowDown from "../../Common/Arrows/ArrowDown";
import { useSwipeable } from "react-swipeable";
import ArrowLeftIcon from "../../assets/icons/ArrowLeftOrange.svg";
import ArrowRightIcon from "../../assets/icons/ArrowRightOrange.svg";

const TailoredPrograms = () => {
  const headstartItems = [
    {
      icon: HeadstartIconOne,
      text: strings.head_start_text_one,
      tag: strings.ideal_for_text,
      mobileIcon: HeadstartIconOneMobile,
    },
    {
      icon: HeadstartIconTwo,
      text: strings.head_start_text_two,
      tag: strings.deliverables_text,
      mobileIcon: HeadstartIconTwoMobile,
    },
    {
      icon: HeadstartIconThree,
      text: strings.head_start_text_three,
      tag: strings.product_stage_text,
      mobileIcon: HeadstartIconThreeMobile,
    },
    {
      icon: HeadstartIconFour,
      text: strings.head_start_text_four,
      tag: strings.length_text,
      mobileIcon: HeadstartIconFourMobile,
    },
  ];

  const productLaunchpadItems = [
    {
      icon: ProductLaunchpadIconOne,
      text: strings.product_launchpad_text_one,
      tag: strings.ideal_for_text,
      mobileIcon: ProductLaunchpadIconOneMobile,
    },
    {
      icon: ProductLaunchpadIconTwo,
      text: strings.product_launchpad_text_two,
      tag: strings.deliverables_text,
      mobileIcon: ProductLaunchpadIconTwoMobile,
    },
    {
      icon: ProductLaunchpadIconThree,
      text: strings.product_launchpad_text_three,
      tag: strings.product_stage_text,
      mobileIcon: ProductLaunchpadIconThreeMobile,
    },
  ];

  const teamBoostItems = [
    {
      icon: TeamBoostIconOne,
      text: strings.team_boost_text_one,
      tag: strings.ideal_for_text,
      mobileIcon: TeamBoostIconOneMobile,
    },
    {
      icon: TeamBoostIconTwo,
      text: strings.team_boost_text_two,
      tag: strings.deliverables_text,
      mobileIcon: TeamBoostIconTwoMobile,
    },
    {
      icon: TeamBoostIconThree,
      text: strings.team_boost_text_three,
      tag: strings.product_stage_text,
      mobileIcon: TeamBoostIconThreeMobile,
    },
  ];

  const items = [
    {
      icon: HeadstartIcon,
      title: strings.tailored_programs_option_one_title,
      subtitle: strings.tailored_programs_option_one_text,
      data: headstartItems,
      link: strings.head_start_link,
    },
    {
      icon: ProductLaunchpadIcon,
      title: strings.tailored_programs_option_two_title,
      subtitle: strings.tailored_programs_option_two_text,
      data: productLaunchpadItems,
      link: strings.product_launchpad_link,
    },
    {
      icon: TeamBoostIcon,
      title: strings.tailored_programs_option_three_title,
      subtitle: strings.tailored_programs_option_three_text,
      data: teamBoostItems,
      link: strings.team_boost_link,
    },
  ];

  const [pressedItem, setPressedItem] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const handleHover = () => {
    setIsHovered(true);
  };
  const handleHoverOut = () => {
    setIsHovered(false);
  };
  const handleItemClick = (index) => {
    setPressedItem(index);

    const windowWidth = window.innerWidth;

    const paddingBottomValue =
      windowWidth >= 768 && windowWidth <= 1100 && index === 1 ? 20 : 60;

    const itemContainers = document.querySelectorAll(
      ".tailored-programs-category",
    );
    itemContainers.forEach((item, i) => {
      item.style.paddingBottom =
        i === index ? `${paddingBottomValue}px` : "20px";
    });
  };

  const setEqualHeight = () => {
    const itemContainers = document.querySelectorAll(
      ".tailored-programs-item-content",
    );
    let maxHeight = 0;

    itemContainers.forEach((item) => {
      item.style.height = "auto";
      const height = item.offsetHeight;
      maxHeight = height > maxHeight ? height : maxHeight;
    });

    itemContainers.forEach((item) => {
      item.style.height = `${maxHeight}px`;
    });
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setEqualHeight();
  }, [windowWidth, pressedItem]);

  const onPressLeft = () => {
    if (pressedItem === 0) {
      setPressedItem(2);
    } else {
      setPressedItem((prev) => prev - 1);
    }
  };
  const onPressRight = () => {
    if (pressedItem === 2) {
      setPressedItem(0);
    } else {
      setPressedItem((prev) => prev + 1);
    }
  };

  const swipeHandlerOne = useSwipeable({
    onSwipedLeft: () => {
      onPressRight();
    },
    preventScrollOnSwipe: true,
    delta: {
      up: 500,
      down: 500,
    },
    onSwipedRight: () => {
      onPressLeft();
    },
  });
  const swipeHandlerTwo = useSwipeable({
    onSwipedLeft: () => {
      onPressRight();
    },
    preventScrollOnSwipe: true,
    delta: {
      up: 500,
      down: 500,
    },
    onSwipedRight: () => {
      onPressLeft();
    },
  });
  const swipeHandlerThree = useSwipeable({
    onSwipedLeft: () => {
      onPressRight();
    },
    preventScrollOnSwipe: true,
    delta: {
      up: 500,
      down: 500,
    },
    onSwipedRight: () => {
      onPressLeft();
    },
  });

  return (
    <div className="tailored-programs-container" id="tailoredprograms">
      <div
        className="tailored-programs-background-image"
        data-aos="animation-line"
        data-aos-offset="0"
        data-aos-delay="400"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="748"
          height="487"
          viewBox="0 0 748 487"
          fill="none"
        >
          <path
            stroke="url(#paint0_linear_837_51812)"
            class="svg-elem-1"
            strokeWidth="2px"
            d="M-36.9003 439.05C-22.5303 472.35 15.1997 493.36 50.9997 484.66C68.4159 480.41 83.8099 470.231 94.5397 455.87C105.062 441.386 109.577 423.395 107.14 405.66C104.89 388.57 96.6897 372.03 83.3997 360.86C71.1698 350.57 53.7797 345.3 37.9997 348.86C21.6397 352.59 11.0498 366.57 10.3097 383.09C9.49975 401.21 18.5098 418.78 30.9997 431.36C44.1697 444.55 62.3698 452.94 81.1797 449.49C99.9897 446.04 112.26 431.35 115.07 412.82C118.39 390.96 109.77 370.17 105.85 349.03C102.455 330.501 104.138 311.399 110.72 293.75C123.27 259.62 152.5 230.97 188.24 222.38C207.8 217.68 230.62 218.68 247.33 230.95C262.54 242.12 268.97 262.2 265.27 280.43C261.53 298.84 247.42 312.38 229.13 316.21C211 320 191.32 313.93 180.28 298.66C169.69 284.03 167.8 264.04 173.6 247.16C180 228.66 196 215.12 214.1 208.66C255.1 194.15 297.16 216.23 323.38 247.58C350.84 280.41 364.09 322.06 388.38 356.93C409.33 387.02 443.38 416.2 482.38 413.77C521 411.4 548 378.12 559.89 343.93C575.04 300.38 572.61 252.93 585.67 208.84C597.39 169.26 623.86 129.68 666.67 120.31C686.131 115.862 706.559 120.441 722.26 132.77C736.82 144.21 747.12 162.1 745.17 181.04C743.17 200.64 728.66 217.38 711.03 225.23C690.03 234.57 664.89 231.51 644.82 221.23C612.51 204.66 592 168.66 592 132.59C592.08 90.0096 622.6 55.7496 656.34 33.5396C675.07 21.2196 695.34 11.5396 715.57 1.94965C716.88 1.32965 715.74 -0.610352 714.43 -0.050354C674.91 18.7596 633.26 38.9496 608.01 76.3796C597.39 92.0996 590.5 110.28 589.81 129.38C589.332 146.838 593.274 164.133 601.27 179.66C618.33 213.21 654.57 237.3 693.1 232.39C711.58 230.04 728.66 220.12 738.94 204.39C748.644 189.798 750.177 171.246 743 155.26C735.77 139.16 721.31 126.63 704.92 120.45C687.52 113.88 668.24 115.72 
            651.31 122.87C612.31 139.35 590.91 178.2 580.97 217.48C575.35 239.65 572.83 262.38 569.88 285.02C566.78 308.87 562.99 333.24 552.96 355.32C544.79 373.32 532.23 389.97 515.39 400.62C498.122 411.539 476.985 414.484 457.39 408.7C415.88 396.7 389.88 358.8 370.7 322.7C351.07 285.76 333.26 245.54 297.7 220.92C281.27 209.54 261.7 201.92 241.52 201.8C223.24 201.68 204.71 207.64 190.52 219.31C176.849 230.44 168.663 246.941 168.07 264.56C167.53 280.86 173.5 297.8 186.23 308.43C198.96 319.06 217 321.93 232.77 317.66C248.96 313.27 261.77 301.28 266.45 285.09C270.99 269.4 268.1 251.4 258.1 238.37C246.39 223.1 226.46 217.26 207.88 217.6C172.02 218.27 139.41 240.03 120.32 269.6C110.689 284.37 104.579 301.155 102.46 318.66C101.449 327.522 101.61 336.479 102.94 345.3C104.77 357.45 108.5 369.2 
            111.05 381.2C115.38 401.61 115.72 427.86 96.3598 441.02C77.6598 453.74 53.3598 447.81 37.1598 434.02C22.1598 421.2 10.7698 401.27 12.7098 380.97C13.6297 371.39 18.1797 362.28 26.0998 356.6C33.4498 351.33 42.6897 349.6 51.5997 350.21C92.6497 353.21 114.36 401.03 101.95 437.15C95.3898 456.19 79.6998 471.41 61.1998 479.03C42.6214 486.664 21.684 486.105 3.53975 477.49C-13.6642 469.404 -27.3432 455.341 -34.9503 437.92C-35.5303 436.59 -37.4703 437.74 -36.9003 439.05Z"
          ></path>
          <defs>
            <linearGradient
              id="#paint0_linear_837_51812"
              x1="747.474"
              y1="65.6277"
              x2="175.008"
              y2="65.6277"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#30555A" />
              <stop offset="1" stop-color="#063D43" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="tailored-programs-content">
        <h2
          className="tailored-programs-first-text"
          data-aos="animation-scale-y"
          data-aos-delay="400"
        >
          {strings.tailored_programs_title}
        </h2>
        <h1
          className="tailored-programs-second-text"
          data-aos="animation-scale-y"
          data-aos-delay="400"
        >
          {strings.tailored_programs_subtitle}
        </h1>
        <div
          className="tailored-programs-categories-container"
          data-aos="animation-scale-y"
          data-aos-delay="400"
        >
          <div className="tailored-programs-categories">
            <div className="tailored-programs-categories-row">
              {items.map((item, index) => (
                <div
                  key={`${index}`}
                  className={`tailored-programs-category ${
                    index === pressedItem ? "pressed" : ""
                  }`}
                  onClick={() => handleItemClick(index)}
                >
                  <div className="tailored-programs-category-background">
                    <img
                      src={item?.icon}
                      alt="Icon"
                      className="tailored-programs-category-icon"
                      draggable="false"
                      loading="lazy"
                    />
                  </div>
                  <div className="tailored-programs-category-title">
                    {item?.title}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          className="tailored-programs-arrow-icons-container"
          {...swipeHandlerOne}
        >
          <img
            src={ArrowLeftIcon}
            alt="Left arrow"
            className="tailored-programs-arrow-icon"
            style={{ left: "32px" }}
            onClick={onPressLeft}
            draggable="false"
            loading="lazy"
          />
          <img
            src={ArrowRightIcon}
            alt="Right arrow"
            className="tailored-programs-arrow-icon"
            style={{ left: "64px" }}
            onClick={onPressRight}
            draggable="false"
            loading="lazy"
          />
        </div>
        <div
          className="tailored-programs-main-content-desktop"
          data-aos="animation-scale-y"
          data-aos-delay="400"
        >
          <div className="tailored-programs-container-behind" />
          <h2 className="tailored-programs-main-content-desktop-title">
            {items[pressedItem]?.title}
          </h2>
          <p className="tailored-programs-main-content-desktop-subtitle">
            {items[pressedItem]?.subtitle}
          </p>
          <div className="tailored-programs-items-container">
            {items[pressedItem]?.data.map((item, index) => (
              <div className="tailored-programs-item-row" key={`${index}`}>
                <div className="tailored-programs-item-container">
                  <div className="tailored-programs-item-content">
                    <div className="tailored-programs-item-content-icon-container">
                      <img
                        className="tailored-programs-item-content-icon"
                        alt="Icon"
                        src={item?.icon}
                        draggable="false"
                        loading="lazy"
                      />
                    </div>
                    <p className="tailored-programs-item-content-text">
                      {item?.text}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="tailored-programs-additional-icon-and-text-desktop">
            <ArrowDown style={{ marginTop: 30 }} isHoveredText={isHovered} />
            <a
              className="tailored-programs-additional-text-desktop-container"
              target="_blank"
              rel="noopener noreferrer"
              href={items[pressedItem]?.link}
            >
              <h3
                className="tailored-programs-additional-text-desktop"
                onMouseEnter={handleHover}
                onMouseLeave={handleHoverOut}
              >
                {strings.find_out_more_text}
              </h3>
            </a>
          </div>
        </div>
        <div
          className="tailored-programs-main-content-mobile"
          data-aos="animation-scale-y"
          data-aos-delay="400"
        >
          <h2
            className="tailored-programs-category-item-title"
            {...swipeHandlerTwo}
          >
            {items[pressedItem]?.title}
          </h2>
          <h3
            className="tailored-programs-category-item-subtitle"
            {...swipeHandlerThree}
          >
            {items[pressedItem]?.subtitle}
          </h3>
        </div>
        <div
          className="tailored-programs-items-scroll-mobile"
          data-aos="animation-scale-y"
          data-aos-delay="400"
        >
          {items[pressedItem]?.data.map((item, index) => (
            <div className="tailored-programs-item-mobile" key={`${index}`}>
              <div>
                <div className="tailored-programs-item-mobile-icon-container">
                  <img
                    alt="Icon"
                    src={item?.mobileIcon}
                    draggable="false"
                    loading="lazy"
                  />
                </div>
                <h3 className="tailored-programs-item-mobile-text">
                  {item?.text}
                </h3>
              </div>
              <p className="tailored-programs-item-mobile-tag">{item?.tag}</p>
            </div>
          ))}
        </div>
        <a
          className="tailored-programs-additional-icon-and-text"
          target="_blank"
          rel="noopener noreferrer"
          href={items[pressedItem]?.link}
        >
          <ArrowDown style={{ marginTop: 30 }} />
          <p className="tailored-programs-additional-text">
            {strings.find_out_more_text}
          </p>
        </a>
      </div>
    </div>
  );
};
export default TailoredPrograms;
