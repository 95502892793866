import React, { useState } from "react";
import { Image } from "react-bootstrap";
import Arrow from "../../assets/icons/ArrowRight.svg";
import { colors } from "../../Theme/colors";

const ArrowRight = ({ onPress, style, isAlwaysHovered }) => {
  const isMobile = window.innerWidth <= 992;
  const [isHovered, setIsHovered] = useState(false);

  const iconSize = isMobile ? 48 : 64;
  const arrowSize = { width: 14, height: 20 };

  const arrowStyle = {
    width: iconSize,
    height: iconSize,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    display: "flex",
    cursor: "pointer",
    backgroundColor:
      (isHovered && !isMobile) || isAlwaysHovered
        ? colors.orange
        : "transparent",
    ...style,
  };

  return (
    <div
      style={arrowStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onPress}
    >
      <Image style={arrowSize} src={Arrow} />
    </div>
  );
};

export default ArrowRight;
