import React from "react";
import "./Menu.css";
import { strings } from "../../Translations/strings";
import LinkedinIcon from "../../assets/icons/Linkedin.svg";
import DribbbleIcon from "../../assets/icons/Dribbble.svg";
import BeehanceIcon from "../../assets/icons/Beehance.svg";
import MediumIcon from "../../assets/icons/Medium.svg";
import InstagramIcon from "../../assets/icons/Instagram.svg";
import LinkedinIconMobile from "../../assets/icons/LinkedinMobile.svg";
import DribbbleIconMobile from "../../assets/icons/DribbbleMobile.svg";
import BeehanceIconMobile from "../../assets/icons/BeehanceMobile.svg";
import MediumIconMobile from "../../assets/icons/MediumMobile.svg";
import InstagramIconMobile from "../../assets/icons/InstagramMobile.svg";
import LogoIcon from "../../assets/icons/NavbarLogo.svg";
import BigLogo from "../../assets/images/HeroLogo.webp";
import CloseIcon from "../../assets/icons/MenuClose.svg";
import MobileCloseIcon from "../../assets/icons/MenuCloseMobile.svg";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import EmailIcon from "../../assets/icons/MenuEmail.svg";
import LocationIcon from "../../assets/icons/MenuLocation.svg";
import BackgroundImage from "../../assets/images/MenuBackgroundMobile.webp";
import AiToolkit from "../../assets/icons/AiToolkitWhite.svg";

const Menu = ({ closeMenu }) => {
  const subtitles = [
    { title: strings.menu_title_one, to: "stories" },
    { title: strings.menu_title_two, to: "whoweare" },
    { title: strings.menu_title_three, to: "services" },
    { title: strings.menu_title_four, to: "tailoredprograms" },
    { title: strings.menu_title_five, to: "ourteam" },
    { title: strings.menu_title_six, to: "medium" },
    { title: strings.menu_title_seven, to: "jointeam" },
  ];

  const socialMedia = [
    {
      icon: LinkedinIcon,
      text: strings.linkedin_text,
      link: strings.linkedin_link,
    },
    {
      icon: DribbbleIcon,
      text: strings.dribbble_text,
      link: strings.dribbble_link,
    },
    {
      icon: BeehanceIcon,
      text: strings.beehance_text,
      link: strings.behance_link,
    },
    { icon: MediumIcon, text: strings.medium_text, link: strings.medium_link },
    {
      icon: InstagramIcon,
      text: strings.instagram_text,
      link: strings.instagram_link,
    },
  ];

  const socialMediaMobile = [
    {
      icon: LinkedinIconMobile,
      text: strings.linkedin_text,
      link: strings.linkedin_link,
    },
    {
      icon: DribbbleIconMobile,
      text: strings.dribbble_text,
      link: strings.dribbble_link,
    },
    {
      icon: BeehanceIconMobile,
      text: strings.beehance_text,
      link: strings.behance_link,
    },
    {
      icon: MediumIconMobile,
      text: strings.medium_text,
      link: strings.medium_link,
    },
    {
      icon: InstagramIconMobile,
      text: strings.instagram_text,
      link: strings.instagram_link,
    },
  ];

  const info = [
    { icon: EmailIcon, textOne: strings.email_info },
    {
      icon: LocationIcon,
      textOne: strings.address_info_one,
      textTwo: strings.address_info_two,
    },
  ];

  const handleLinkClick = async (to) => {
    await closeMenu();
    scroll.scrollTo(document.getElementById(to).offsetTop);
  };

  return (
    <div id="menu" className="menu-container">
      <img
        src={BackgroundImage}
        alt="Background"
        className="menu-background-image"
        draggable="false"
      />
      <div className="menu-background-color-one" />
      <div className="menu-background-color-two" />
      <div className="menu-content">
        <div className="close-icon-container-desktop" onClick={closeMenu}>
          <img
            src={CloseIcon}
            alt="Close"
            className="close-icon-desktop"
            draggable="false"
          />
        </div>
        <div className="menu-left-container">
          <img
            src={LogoIcon}
            alt="Logo"
            className="menu-logo"
            draggable="false"
          />
          <img
            src={BigLogo}
            alt="BigLogo"
            className="menu-biglogo"
            draggable="false"
          />
        </div>
        <div className="menu-right-container">
          <div className="top-right-component">
            <div className="top-right-left">
              {info.map((item, index) => (
                <div key={`${index}`} className="top-right-left-info-item">
                  <img
                    src={item?.icon}
                    alt={item?.textOne}
                    className="top-right-left-info-icon"
                    draggable="false"
                  />
                  {index === 0 ? (
                    <a
                      className="top-right-left-info-text"
                      href="mailto:info@scaleup.ba?subject=ScaleUp - Info"
                    >
                      {item?.textOne}
                    </a>
                  ) : (
                    <span className="top-right-left-info-text">
                      {item?.textOne}
                      <br />
                      {item?.textTwo}
                    </span>
                  )}
                </div>
              ))}
            </div>
            <div className="top-right-right">
              {subtitles.map((item, index) => (
                <ScrollLink
                  key={`${index}`}
                  smooth={true}
                  duration={1000}
                  onClick={() => handleLinkClick(item?.to)}
                >
                  <p
                    className="subtitle-item"
                    data-aos="animation-scale-y"
                    data-aos-delay="0"
                  >
                    {item?.title}
                  </p>
                </ScrollLink>
              ))}
            </div>
          </div>
          <div className="bottom-right-component">
            {socialMedia.map((item, index) => (
              <div key={`${index}`}>
                <a
                  href={item?.link}
                  className="social-media-item"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={item?.icon}
                    alt={item?.text}
                    className="social-media-icon"
                    style={{ marginBottom: index === 0 ? 3 : 0 }}
                    draggable="false"
                  />
                  <span className="social-media-text">{item?.text}</span>
                </a>
              </div>
            ))}
          </div>
        </div>
        <div className="menu-mobile-header">
          <img
            src={LogoIcon}
            alt="Logo"
            className="menu-logo-mobile"
            draggable="false"
          />
          <div className="close-icon-container-mobile" onClick={closeMenu}>
            <img
              src={MobileCloseIcon}
              alt="Close"
              className="close-icon-mobile"
              draggable="false"
            />
          </div>
        </div>
        <div
          onClick={() => window.open("https://tools.scaleup.ba/", "_blank")}
          className="menu-ai-toolkit-container"
        >
          <div className="menu-ai-toolkit-content">
            <img src={AiToolkit} alt="Rocket" draggable="false" />
            <span className="menu-ai-toolkit-text">{strings.ai_toolkit}</span>
          </div>
        </div>
        <div className="subtitles-mobile">
          {subtitles.map((item, index) => (
            <ScrollLink
              key={`${index}`}
              smooth={true}
              duration={1000}
              onClick={() => handleLinkClick(item?.to)}
            >
              <p className="subtitle-item-mobile">{item?.title}</p>
            </ScrollLink>
          ))}
        </div>
        <div className="social-media-icons-mobile">
          {socialMediaMobile.map((item, index) => (
            <div key={`${index}`} className="social-media-item">
              <a href={item?.link} target="_blank" rel="noopener noreferrer">
                <img
                  src={item?.icon}
                  alt={item?.text}
                  className="social-media-icon-mobile"
                  draggable="false"
                />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Menu;
