import React, { useEffect, useRef, useState } from "react";
import "./Journey.css";
import Video from "../../assets/videos/Intro.mp4";
import { strings } from "../../Translations/strings";
import Thumbnail from "../../assets/images/VideoThumbnail.webp";
import PlayIcon from "../../assets/icons/Play.svg";

const Journey = () => {
  const videoRef = useRef(null);
  const [isPaused, setIsPaused] = useState(true);

  const handleVideoClick = () => {
    setIsPaused(!isPaused);
  };
  const handleIconClick = () => {
    const video = videoRef?.current;
    video?.play();
    setIsPaused(false);
  };

  useEffect(() => {
    const video = videoRef.current;
    document
      .querySelector(".play-icon-overlay")
      ?.classList?.add("background-image");
    const handlePlay = () => {
      setIsPaused(false);
      document.querySelector(".shapes-container").classList.add("animate");
    };

    const handlePause = () => {
      setIsPaused(true);
      document.querySelector(".shapes-container").classList.remove("animate");
      document
        .querySelector(".play-icon-overlay")
        ?.classList?.remove("background-image");
    };

    video.addEventListener("play", handlePlay);
    video.addEventListener("pause", handlePause);
    return () => {
      video.removeEventListener("play", handlePlay);
      video.removeEventListener("pause", handlePause);
    };
  }, []);

  return (
    <div className="journey-container" id="journey">
      <div
        className="journey-background-image"
        data-aos="animation-line"
        data-aos-offset="0"
        data-aos-delay="400"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="643"
          height="820"
          viewBox="0 0 643 820"
          fill="none"
        >
          <path
            stroke-width="2px"
            d="M66.9994 0.0232937C36.7294 6.55329 12.5694 31.1433 3.55937 60.3433C-5.77063 90.5933 3.65937 122.343 26.9494 143.543C49.4394 163.993 82.9494 173.643 112.049 162.463C139.269 152.003 162.919 122.173 149.779 92.3533C138.679 67.1733 105.779 52.4533 80.0794 63.4433C54.3794 74.4333 41.8894 105.193 46.5894 131.753C52.5294 165.263 85.0894 183.753 114.859 193.123C150.919 204.543 192.929 206.223 222.469 232.503C245.759 253.223 257.179 285.503 254.859 316.333C253.798 331.783 248.9 346.724 240.609 359.803C231.439 373.993 215.789 386.143 198.529 387.803C179.329 389.653 165.529 374.923 164.139 356.433C162.629 336.103 174.949 318.333 186.659 302.903C209.029 273.443 237.659 244.113 274.819 235.033C307.729 227.033 347.309 236.093 364.759 267.423C384.539 302.943 370.459 345.333 349.489 376.673C338.109 393.673 324.579 409.183 311.259 424.673C297.719 440.423 284.059 456.093 271.259 472.483C247.039 503.563 225.909 537.673 216.799 576.413C208.249 612.753 210.799 651.833 230.969 684.023C249.709 714.023 279.969 735.193 312.149 748.763C352.349 765.763 396.049 772.443 438.649 780.583L575.289 806.703L640.809 819.223C642.219 819.493 642.809 817.333 641.409 "
            stroke="url(#paint0_linear_837_51812)"
            class="svg-elem-1"
          ></path>
          <defs>
            <linearGradient
              id="paint0_linear_837_51812"
              x1="597.406"
              y1="390.52"
              x2="552.406"
              y2="1017.52"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#395C60"></stop>
              <stop offset="1" stop-color="#07BBCE"></stop>
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="journey-content">
        <h1
          className="journey-first-text"
          data-aos="animation-scale-y"
          data-aos-delay="400"
        >
          {strings.journey_title}
        </h1>
        <h2
          className="journey-second-text"
          data-aos="animation-scale-y"
          data-aos-delay="400"
        >
          {strings.journey_subtitle}
        </h2>
        <div className="journey-video-container">
          {isPaused && (
            <div className="play-icon-overlay">
              <div className="play-icon" onClick={handleIconClick}>
                <img
                  alt="Play"
                  src={PlayIcon}
                  className="play-video"
                  draggable="false"
                  loading="lazy"
                />
              </div>
            </div>
          )}
          <video
            width={"100%"}
            style={{ borderRadius: 20 }}
            onClick={handleVideoClick}
            ref={videoRef}
            poster={Thumbnail}
            controls={true}
            controlsList="nodownload noremoteplayback"
            playsInline={true}
            preload="none"
          >
            <source src={Video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="shapes-container">
          <div className="shapes-container-desktop-left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33.388"
              height="200"
              fill="#F5896B"
              class="shape1"
            >
              <path
                d="M16.8034 0.429688C26.0778 0.429688 33.497 6.61259 33.497 14.2382V185.761C33.497 193.387 26.0778 199.57 16.8034 199.57C7.52907 199.57 0.109375 193.387 0.109375 185.761V14.2382C0.109375 6.61259 7.52907 0.429688 16.8034 0.429688Z"
                fill="#F5896B"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33.388"
              height="123.04"
              fill="#F5896B"
              className="shape2"
            >
              <path
                d="M16.6936 0.785156C25.9679 0.785156 33.3876 6.96809 33.3876 14.5937V110.017C33.3876 117.642 25.9679 123.825 16.6936 123.825C7.41919 123.825 0 117.642 0 110.017V14.5937C0 6.96809 7.41919 0.785156 16.6936 0.785156Z"
                fill="#F5896B"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33.388"
              height="281.941"
              fill="#F5896B"
              className="shape3"
            >
              <path
                d="M16.8034 0.429688C26.0778 0.429688 33.497 6.61259 33.497 14.2382V268.562C33.497 276.188 26.0778 282.37 16.8034 282.37C7.52907 282.37 0.109375 276.188 0.109375 268.562V14.2382C0.109375 6.61259 7.52907 0.429688 16.8034 0.429688Z"
                fill="#F5896B"
              />
            </svg>
          </div>
          <div className="shapes-container-desktop-right">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33.388"
              height="200"
              fill="#F5896B"
              class="shape1"
            >
              <path
                d="M16.8034 0.429688C26.0778 0.429688 33.497 6.61259 33.497 14.2382V185.761C33.497 193.387 26.0778 199.57 16.8034 199.57C7.52907 199.57 0.109375 193.387 0.109375 185.761V14.2382C0.109375 6.61259 7.52907 0.429688 16.8034 0.429688Z"
                fill="#F5896B"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33.388"
              height="123.04"
              fill="#F5896B"
              className="shape2"
            >
              <path
                d="M16.6936 0.785156C25.9679 0.785156 33.3876 6.96809 33.3876 14.5937V110.017C33.3876 117.642 25.9679 123.825 16.6936 123.825C7.41919 123.825 0 117.642 0 110.017V14.5937C0 6.96809 7.41919 0.785156 16.6936 0.785156Z"
                fill="#F5896B"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33.388"
              height="281.941"
              fill="#F5896B"
              className="shape3"
            >
              <path
                d="M16.8034 0.429688C26.0778 0.429688 33.497 6.61259 33.497 14.2382V268.562C33.497 276.188 26.0778 282.37 16.8034 282.37C7.52907 282.37 0.109375 276.188 0.109375 268.562V14.2382C0.109375 6.61259 7.52907 0.429688 16.8034 0.429688Z"
                fill="#F5896B"
              />
            </svg>
          </div>
          <div className="shapes-container-mobile-left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="105"
              viewBox="0 0 9 105"
              fill="none"
            >
              <path
                d="M4.14752 0C6.36975 0 8.14746 2.74797 8.14746 6.13712V98.1021C8.14746 101.4 6.36975 104.148 4.14752 104.148C1.97468 104.148 0.147461 101.4 0.147461 98.1021V6.13712C0.147461 2.74797 1.97468 0 4.14752 0Z"
                fill="#F5896B"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="56"
              viewBox="0 0 9 56"
              fill="none"
            >
              <path
                d="M4.4306 0.75C6.65283 0.75 8.43066 3.49797 8.43066 6.88712V49.2973C8.43066 52.6864 6.65283 55.4344 4.4306 55.4344C2.20837 55.4344 0.430664 52.6864 0.430664 49.2973V6.88712C0.430664 3.49797 2.20837 0.75 4.4306 0.75Z"
                fill="#F5896B"
              />
            </svg>
          </div>
          <div className="shapes-container-mobile-right">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="56"
              viewBox="0 0 9 56"
              fill="none"
            >
              <path
                d="M4.4306 0.75C6.65283 0.75 8.43066 3.49797 8.43066 6.88712V49.2973C8.43066 52.6864 6.65283 55.4344 4.4306 55.4344C2.20837 55.4344 0.430664 52.6864 0.430664 49.2973V6.88712C0.430664 3.49797 2.20837 0.75 4.4306 0.75Z"
                fill="#F5896B"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="105"
              viewBox="0 0 9 105"
              fill="none"
            >
              <path
                d="M4.14752 0C6.36975 0 8.14746 2.74797 8.14746 6.13712V98.1021C8.14746 101.4 6.36975 104.148 4.14752 104.148C1.97468 104.148 0.147461 101.4 0.147461 98.1021V6.13712C0.147461 2.74797 1.97468 0 4.14752 0Z"
                fill="#F5896B"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Journey;
