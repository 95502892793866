import React, { useState, useEffect } from "react";
import { Navbar as BootstrapNavbar } from "react-bootstrap";
import Logo from "../../assets/icons/NavbarLogo.svg";
import Menu from "../../assets/icons/NavbarMenu.svg";
import "./Navbar.css";
import AiToolkit from "../../assets/icons/AiToolkitWhite.svg";
import { strings } from "../../Translations/strings";

const Navbar = ({ openMenu }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [passedThreshold, setPassedThreshold] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
      if (window.scrollY > 1200) {
        setPassedThreshold(true);
      }
      if (window.scrollY < 200) {
        setPassedThreshold(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const scrollThreshold = 600;
      if (currentScrollY > scrollThreshold) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [windowWidth]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navbarStyle = {
    backgroundColor: isScrolled ? "#05353a" : "transparent",
    transition: "background-color 0.3s ease",
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <BootstrapNavbar sticky="top" className="navbar" style={navbarStyle}>
      <div className="navbar-content">
        <div className="navbar-logo-container" onClick={scrollToTop}>
          {scrollPosition > 1200 && (
            <img
              src={Logo}
              alt="Logo"
              className="navbar-logo"
              draggable="false"
            />
          )}
        </div>
        <div className="navabar-right-container">
          <div
            className={`ai-toolkit-container ${scrollPosition < 1200 ? "" : "small"}`}
            onClick={() => window.open("https://tools.scaleup.ba/", "_blank")}
          >
            <img src={AiToolkit} alt="Rocket" draggable="false" />
            <span
              className={`ai-toolkit-text ${scrollPosition > 1200 ? "small" : passedThreshold ? "slide-in" : ""}`}
            >
              {strings.ai_toolkit}
            </span>
          </div>
          <img
            src={Menu}
            alt="Menu"
            className="navbar-menu"
            onClick={openMenu}
            draggable="false"
          />
        </div>
      </div>
    </BootstrapNavbar>
  );
};

export default Navbar;
