import React, { useState } from "react";
import { Image } from "react-bootstrap";
import Arrow from "../../assets/icons/ArrowDown.svg";
import { colors } from "../../Theme/colors";

const ArrowDown = ({ onPress, style, isHoveredText }) => {
  const [isHovered, setIsHovered] = useState(false);

  const arrowStyle = {
    width: 48,
    height: 48,
    backgroundColor: colors.orange,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    display: "flex",
    overflow: "hidden",
    position: "relative",
    cursor: "pointer",
    ...style,
  };

  const imageStyle = {
    width: 10,
    height: 20,
    position: "absolute",
    transition:
      isHovered || isHoveredText
        ? "transform 0.2s linear"
        : "transform 0.0000001s linear",
    transform:
      isHovered || isHoveredText ? "translateY(47px)" : "translateY(0)",
  };

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleHoverOut = () => {
    setIsHovered(false);
  };

  return (
    <div
      style={arrowStyle}
      onClick={onPress}
      onMouseEnter={handleHover}
      onMouseLeave={handleHoverOut}
    >
      <Image style={{ ...imageStyle, top: -33 }} src={Arrow} />
      <Image style={{ ...imageStyle }} src={Arrow} />
    </div>
  );
};

export default ArrowDown;
