import React from "react";
import "./OneInput.css";

const OneInput = ({
  icon,
  placeholder,
  value,
  setValue,
  isFile,
  onFileUpload,
  uploadedFileName,
  isError,
  errorText,
}) => {
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    onFileUpload(file);
  };

  return (
    <div className="one-input-field-container">
      <div
        className="one-input-field-wrapper"
        style={{ borderColor: isError && "red" }}
      >
        <img
          src={icon}
          alt="Icon"
          className="one-input-icon"
          draggable="false"
          loading="lazy"
        />
        <div className="one-input-vertical-line" />
        {isFile ? (
          <label htmlFor="file-upload" className="one-input-file-label">
            {uploadedFileName ? uploadedFileName : placeholder}
            <input
              id="file-upload"
              type="file"
              accept=".pdf, .doc, .docx"
              className="one-input-custom-input one-input-file-input"
              onChange={handleFileUpload}
            />
          </label>
        ) : (
          <input
            type="text"
            placeholder={placeholder}
            className="one-input-custom-input"
            value={value}
            onChange={handleChange}
          />
        )}
      </div>
      {isError && <p className="input-error-text">{errorText}</p>}
    </div>
  );
};
export default OneInput;
