import React from "react";
import "./Footer.css";
import { strings } from "../../Translations/strings";
import FacebookIcon from "../../assets/icons/FooterFacebook.svg";
import MediumIcon from "../../assets/icons/FooterMedium.svg";
import InstagramIcon from "../../assets/icons/FooterInstagram.svg";
import LinkedinIcon from "../../assets/icons/FooterLinkedin.svg";
import { Link } from "react-scroll";

const LinkItem = ({ to, title }) => {
  return (
    <Link
      activeClass="active"
      to={to}
      spy={true}
      smooth={true}
      duration={1000}
      offset={0}
    >
      <p className="footer-link-text">{title}</p>
    </Link>
  );
};
const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <div id="footer">
      <div className="footer-container">
        <div>
          <h2 className="footer-title">{strings.footer_title_one}</h2>
          <div className="footer-title-one-texts">
            <p className="footer-title-one-text">
              {strings.footer_scaleup_text_one}
            </p>
            <p className="footer-title-one-text">
              {strings.footer_scaleup_text_two}
            </p>
          </div>
          <div className="footer-icon-row">
            <a
              href={strings.facebook_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={FacebookIcon}
                alt="Facebook"
                className="footer-social-media-icon"
                draggable="false"
                loading="lazy"
              />
            </a>
            <a
              href={strings.linkedin_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={LinkedinIcon}
                alt="Linkedin"
                className="footer-social-media-icon"
                draggable="false"
                loading="lazy"
              />
            </a>
            <a
              href={strings.instagram_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={InstagramIcon}
                alt="Instagram"
                className="footer-social-media-icon"
                draggable="false"
                loading="lazy"
              />
            </a>
            <a
              href={strings.medium_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={MediumIcon}
                alt="Medium"
                className="footer-social-media-icon"
                draggable="false"
                loading="lazy"
              />
            </a>
          </div>
        </div>
        <div>
          <h2 className="footer-title">{strings.footer_title_two}</h2>
          <div className="footer-links">
            <div className="footer-links-column">
              <LinkItem to={"stories"} title={strings.menu_title_one} />
              <LinkItem to={"whoweare"} title={strings.menu_title_two} />
              <LinkItem
                to={"services"}
                title={strings.menu_title_three}
              />
              <LinkItem to={"tailoredprograms"} title={strings.menu_title_four} />
            </div>
            <div className="footer-links-column footer-links-second-column">
              <LinkItem to={"ourteam"} title={strings.menu_title_five} />
              <LinkItem to={"medium"} title={strings.menu_title_six} />
              <LinkItem to={"jointeam"} title={strings.menu_title_seven} />
            </div>
          </div>
        </div>
        <div>
          <h2 className="footer-title">{strings.footer_title_three}</h2>
          <div className="footer-title-one-texts">
            <p className="footer-title-one-text">
              {strings.footer_contact_text_one}
            </p>
            <a
              className="footer-title-one-text"
              href="mailto:info@scaleup.ba?subject=ScaleUp - Info"
            >
              {strings.footer_contact_text_two}
            </a>
          </div>
        </div>
      </div>
      <div className="footer-horizontal-line" />
      <p className="footer-copyright">{`${strings.footer_copyright} ${currentYear}`}</p>
    </div>
  );
};
export default Footer;
