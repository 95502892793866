/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { strings } from "../../Translations/strings";
import "./WhoWeAre.css";
import ManifestoShape from "../../assets/images/WhoWeAreShape.webp";
import ManifestoImage from "../../assets/images/WhoWeAreManifesto.webp";
import MissionImage from "../../assets/images/WhoWeAreMission.webp";
import VisionImage from "../../assets/images/WhoWeAreVision.webp";
import CoreValuesImage from "../../assets/images/WhoWeAreCoreValues.webp";
import ArrowLeft from "../../Common/Arrows/ArrowLeft";
import ArrowRight from "../../Common/Arrows/ArrowRight";
import ArrowLeftIcon from "../../assets/icons/ArrowLeftOrange.svg";
import ArrowRightIcon from "../../assets/icons/ArrowRightOrange.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSwipeable } from "react-swipeable";

const WhoWeAre = () => {
  const items = [
    {
      title: strings.who_we_are_option_one,
      text: strings.manifesto_text,
      image: ManifestoImage,
    },
    {
      title: strings.who_we_are_option_two,
      text: strings.mission_text,
      image: MissionImage,
    },
    {
      title: strings.who_we_are_option_three,
      text: strings.vision_text,
      image: VisionImage,
    },
    { title: strings.who_we_are_option_four, image: CoreValuesImage },
  ];

  const coreValueTexts = [
    {
      title: strings.core_values_title_one,
      text: strings.core_values_text_one,
    },
    {
      title: strings.core_values_title_two,
      text: strings.core_values_text_two,
    },
    {
      title: strings.core_values_title_three,
      text: strings.core_values_text_three,
    },
    {
      title: strings.core_values_title_four,
      text: strings.core_values_text_four,
    },
    {
      title: strings.core_values_title_five,
      text: strings.core_values_text_five,
    },
  ];

  const [isScrolling, setIsScrolling] = useState(false);
  const [pressedIndex, setPressedIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window?.innerWidth);

  useEffect(() => {
    AOS.init({
      once: false,
    });
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window?.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const container = document.getElementById(
      "who-we-are-main-content-second-component-scroll",
    );
    if (container) {
      const newPosition = pressedIndex * (container?.offsetWidth + 100);
      container.scrollTo({
        left: newPosition,
        behavior: "auto",
      });
    }
  }, [windowWidth]);

  const handleItemClick = (index) => {
    if (!isScrolling) {
      setIsScrolling(true);
      const container = document.getElementById(
        "who-we-are-main-content-second-component-scroll",
      );
      if (container) {
        const newPosition =
          container.scrollLeft +
            (index - pressedIndex) * (container.offsetWidth + 100) || 1;
        container.style.overflowX = "auto";
        window.requestAnimationFrame(() =>
          container.scrollTo({ left: newPosition, behavior: "smooth" }),
        );
        setTimeout(() => (container.style.overflowX = "hidden"), 800);
      }
      setTimeout(() => {
        setIsScrolling(false);
      }, 800);
      setPressedIndex(index);
    }
  };
  const goToNextIndex = () => {
    if (!isScrolling) {
      setIsScrolling(true);
      const container = document.getElementById(
        "who-we-are-main-content-second-component-scroll",
      );
      if (container) {
        const newPosition = container.scrollLeft + container.offsetWidth + 100;
        if (pressedIndex === 3) {
          container.style.overflowX = "auto";
          window.requestAnimationFrame(() =>
            container.scrollTo({ left: 1, behavior: "smooth" }),
          );
          setTimeout(() => (container.style.overflowX = "hidden"), 800);
        } else {
          container.style.overflowX = "auto";
          window.requestAnimationFrame(() =>
            container.scrollTo({ left: newPosition, behavior: "smooth" }),
          );
          setTimeout(() => (container.style.overflowX = "hidden"), 800);
        }
        setTimeout(() => {
          setIsScrolling(false);
        }, 800);
      }
      if (pressedIndex === 3) {
        setPressedIndex(0);
      } else {
        setPressedIndex((prev) => prev + 1);
      }
    }
  };
  const goToPrevIndex = () => {
    if (!isScrolling) {
      setIsScrolling(true);
      const container = document.getElementById(
        "who-we-are-main-content-second-component-scroll",
      );
      if (container) {
        const newPosition = container.scrollLeft - container.offsetWidth - 100;
        if (pressedIndex === 0) {
          container.style.overflowX = "auto";
          window.requestAnimationFrame(() =>
            container.scrollTo({
              left: 3 * (container.offsetWidth + 100),
              behavior: "smooth",
            }),
          );
          setTimeout(() => (container.style.overflowX = "hidden"), 800);
        } else {
          container.style.overflowX = "auto";
          window.requestAnimationFrame(() =>
            container.scrollTo({ left: newPosition, behavior: "smooth" }),
          );
          setTimeout(() => (container.style.overflowX = "hidden"), 800);
        }
        setTimeout(() => {
          setIsScrolling(false);
        }, 800);
      }
      if (pressedIndex === 0) {
        setPressedIndex(3);
      } else {
        setPressedIndex((prev) => prev - 1);
      }
    }
  };

  const SwipeItems = () => {
    return (
      <div className="who-we-are-arrows">
        <ArrowLeft onPress={goToPrevIndex} />
        <ArrowRight onPress={goToNextIndex} />
      </div>
    );
  };
  const CoreValuesTexts = () => {
    return (
      <div className="core-values-list">
        {coreValueTexts.map((item, index) => (
          <div key={`${index}`} className="core-values-item">
            <span>
              <span className="core-values-dot">•</span>
              <span className="core-values-title">{item?.title}</span>
              <span className="core-values-text">{item?.text}</span>
            </span>
          </div>
        ))}
      </div>
    );
  };

  const onPressLeft = () => {
    if (pressedIndex === 0) {
      setPressedIndex(3);
    } else {
      setPressedIndex((prev) => prev - 1);
    }
  };
  const onPressRight = () => {
    if (pressedIndex === 3) {
      setPressedIndex(0);
    } else {
      setPressedIndex((prev) => prev + 1);
    }
  };

  const swipeHandlerOne = useSwipeable({
    onSwipedLeft: () => {
      onPressRight();
    },
    preventScrollOnSwipe: true,
    delta: {
      up: 500,
      down: 500,
    },
    onSwipedRight: () => {
      onPressLeft();
    },
  });
  const swipeHandlerTwo = useSwipeable({
    onSwipedLeft: () => {
      onPressRight();
    },
    preventScrollOnSwipe: true,
    delta: {
      up: 500,
      down: 500,
    },
    onSwipedRight: () => {
      onPressLeft();
    },
  });
  const swipeHandlerThree = useSwipeable({
    onSwipedLeft: () => {
      onPressRight();
    },
    preventScrollOnSwipe: true,
    delta: {
      up: 500,
      down: 500,
    },
    onSwipedRight: () => {
      onPressLeft();
    },
  });

  return (
    <div id="whoweare" className="who-we-are-container">
      <div
        className="who-we-are-background-image"
        data-aos="animation-line"
        data-aos-offset="0"
        data-aos-delay="400"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="631"
          height="385"
          viewBox="0 0 631 385"
          fill="none"
        >
          <path
            d="M-0.858768 346.511C10.7076 373.314 41.0763 390.224 69.8916 383.222C83.9098 379.801 96.3004 371.608 104.937 360.049C113.406 348.391 117.04 333.91 115.078 319.635C113.267 305.879 106.667 292.566 95.9702 283.576C86.1263 275.293 72.1292 271.052 59.4279 273.917C46.2598 276.919 37.736 288.172 37.1404 301.469C36.4884 316.053 43.7405 330.195 53.7937 340.321C64.3941 350.937 79.0433 357.691 94.1833 354.914C109.323 352.137 119.2 340.313 121.461 325.398C124.134 307.803 117.195 291.069 114.04 274.054C111.308 259.14 112.662 243.765 117.96 229.559C128.061 202.088 151.589 179.028 180.356 172.114C196.099 168.331 214.467 169.136 227.917 179.012C240.159 188.002 245.335 204.165 242.357 218.838C239.346 233.656 227.989 244.554 213.268 247.637C198.675 250.688 182.835 245.802 173.949 233.511C165.425 221.736 163.903 205.646 168.572 192.059C173.723 177.168 186.601 166.27 201.17 161.071C234.171 149.392 268.025 167.164 289.129 192.397C311.232 218.822 321.896 252.346 341.447 280.412C358.31 304.632 385.717 328.119 417.108 326.163C448.193 324.255 469.925 297.468 479.495 269.949C491.689 234.896 489.733 196.703 500.245 161.215C509.679 129.358 530.984 97.4999 565.442 89.958C581.106 86.3784 597.549 90.0639 610.186 99.987C621.905 109.195 630.196 123.595 628.626 138.839C627.016 154.615 615.337 168.089 601.147 174.408C584.244 181.925 564.009 179.462 547.855 171.188C521.849 157.851 505.34 128.875 505.34 99.8421C505.405 65.5697 529.97 37.9939 557.127 20.1172C572.203 10.2009 588.518 2.40947 604.801 -5.30948C605.856 -5.80852 604.938 -7.37002 603.884 -6.91927C572.074 8.22082 538.55 24.4717 518.227 54.5989C509.679 67.2519 504.133 81.8849 503.578 97.2584C503.193 111.31 506.366 125.231 512.802 137.729C526.533 
        164.733 555.703 184.123 586.715 180.171C601.59 178.279 615.337 170.295 623.612 157.634C631.422 145.889 632.656 130.957 626.88 118.089C621.06 105.13 609.421 95.0449 596.229 90.0707C582.224 84.7825 566.706 86.2635 553.079 92.0185C521.688 105.283 504.463 136.553 496.462 168.17C491.939 186.014 489.91 204.31 487.536 222.532C485.041 241.729 481.99 261.345 473.917 279.117C467.341 293.605 457.232 307.006 443.677 315.578C429.778 324.368 412.765 326.738 396.993 322.082C363.582 312.423 342.655 281.918 327.217 252.861C311.417 223.128 297.082 190.755 268.459 170.939C255.235 161.779 239.483 155.646 223.24 155.549C208.527 155.452 193.612 160.25 182.191 169.643C171.188 178.602 164.598 191.883 164.121 206.064C163.686 219.184 168.491 232.819 178.738 241.375C188.984 249.931 203.504 252.241 216.198 248.804C229.229 245.271 239.539 235.62 243.306 222.589C246.961 209.96 244.635 195.472 236.586 184.984C227.16 172.693 211.119 167.993 196.164 168.266C167.3 168.806 141.052 186.32 125.687 210.121C117.935 222.01 113.017 235.519 111.312 249.609C110.498 256.743 110.628 263.952 111.698 271.052C113.171 280.831 116.173 290.289 118.226 299.947C121.711 316.375 121.984 337.504 106.402 348.096C91.3501 358.334 71.7911 353.561 58.7518 342.462C46.6784 332.143 37.5106 316.102 39.0721 299.762C39.8126 292.051 43.4749 284.719 49.8497 280.147C55.7657 275.905 63.2029 274.513 70.3745 275.004C103.415 277.418 120.89 315.908 110.901 344.981C105.621 360.306 92.9921 372.557 78.1015 378.69C63.1479 384.835 46.2955 384.385 31.6912 377.451C17.8438 370.942 6.83366 359.623 0.710779 345.601C0.24394 344.53 -1.31756 345.456 -0.858768 346.511Z"
            stroke="url(#paint0_linear_837_51812)"
            class="svg-elem-1"
            strokeWidth="2px"
          ></path>
          <defs>
            <linearGradient
              id="paint0_linear_837_50855"
              x1="630.481"
              y1="45.9447"
              x2="169.706"
              y2="45.9447"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#30555A" />
              <stop offset="1" stopColor="#063D43" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="who-we-are-content">
        <h2
          className="who-we-are-first-text"
          data-aos="animation-scale-y"
          data-aos-delay="400"
        >
          {strings.who_we_are_title}
        </h2>
        <h1
          className="who-we-are-second-text"
          data-aos="animation-scale-y"
          data-aos-delay="400"
        >
          {strings.who_we_are_subtitle}
        </h1>
        <h1
          className="who-we-are-second-text-mobile"
          data-aos="animation-scale-y"
          data-aos-delay="400"
          {...swipeHandlerOne}
        >
          {`${strings.our_text} ${items[pressedIndex]?.title}`}
        </h1>
        <div className="who-we-are-arrow-icons-container" {...swipeHandlerTwo}>
          <img
            src={ArrowLeftIcon}
            alt="Left arrow"
            className="who-we-are-arrow-icon"
            style={{ left: "32px" }}
            onClick={onPressLeft}
            draggable="false"
            loading="lazy"
          />
          <img
            src={ArrowRightIcon}
            alt="Right arrow"
            className="who-we-are-arrow-icon"
            style={{ left: "64px" }}
            onClick={onPressRight}
            draggable="false"
            loading="lazy"
          />
        </div>
        <div className="who-we-are-arrows-container-desktop">
          <SwipeItems />
        </div>
        <div className="who-we-are-main-content-mobile">
          <span
            className="who-we-are-main-content-text"
            data-aos="animation-scale-y"
            data-aos-delay="400"
            {...swipeHandlerThree}
          >
            {pressedIndex === 3 ? (
              <CoreValuesTexts />
            ) : (
              items[pressedIndex]?.text
            )}
          </span>
        </div>
        <div
          className="who-we-are-main-content-desktop"
          data-aos="animation-scale-y"
          data-aos-delay="200"
          data-aos-offset="100"
        >
          <div className="who-we-are-main-content-first-component">
            {items?.map((item, index) => (
              <div
                key={`${index}`}
                className="who-we-are-main-content-first-component-line-and-text"
                onClick={() => handleItemClick(index)}
              >
                <div
                  className={`who-we-are-main-content-first-component-line ${
                    index === pressedIndex
                      ? "who-we-are-main-content-first-component-line-selected"
                      : ""
                  }`}
                />
                <span
                  className={`who-we-are-main-content-first-component-text ${
                    index === pressedIndex
                      ? "who-we-are-main-content-first-component-text-selected"
                      : ""
                  }`}
                >
                  {item?.title}
                </span>
                {index === pressedIndex && (
                  <div className="who-we-are-main-content-first-component-activity-line">
                    <svg
                      width="110"
                      height="16"
                      viewBox="0 0 110 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_837_50873)">
                        <g clip-path="url(#clip1_837_50873)">
                          <circle
                            cx="102.004"
                            cy="8"
                            r="8"
                            fill="#F5896B"
                            class="svg-elem-2"
                          ></circle>
                          <g filter="url(#filter0_d_837_50873)">
                            <circle
                              cx="102.004"
                              cy="8"
                              r="4"
                              fill="#F5896B"
                              class="svg-elem-3"
                            ></circle>
                          </g>
                        </g>
                        <path
                          d="M1.35998 8.5H103.693"
                          stroke="#F5896B"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="svg-elem-4"
                        ></path>
                        <circle
                          cx="2.50391"
                          cy="8.5"
                          r="2.5"
                          fill="#F5896B"
                          class="svg-elem-5"
                        ></circle>
                      </g>
                      <defs>
                        <filter
                          id="filter0_d_837_50873"
                          x="94.0039"
                          y="2"
                          width="16"
                          height="16"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          ></feFlood>
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          ></feColorMatrix>
                          <feOffset dy="2"></feOffset>
                          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.30171 0"
                          ></feColorMatrix>
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_837_50873"
                          ></feBlend>
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_837_50873"
                            result="shape"
                          ></feBlend>
                        </filter>
                        <clipPath id="clip0_837_50873">
                          <rect
                            width="110"
                            height="16"
                            fill="white"
                            transform="translate(0.00390625)"
                            class="svg-elem-6"
                          ></rect>
                        </clipPath>
                        <clipPath id="clip1_837_50873">
                          <rect
                            width="16"
                            height="16"
                            fill="white"
                            transform="translate(94.0039)"
                            class="svg-elem-7"
                          ></rect>
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div class="who-we-are-main-content-second-component-scroll-wrapper">
            <div
              className="who-we-are-main-content-second-component"
              id="who-we-are-main-content-second-component-scroll"
            >
              {items?.map((item) => (
                <div className="who-we-are-main-content-second-component-content">
                  <span className="who-we-are-main-content-second-component-title">
                    {item?.title}
                  </span>
                  <h3 className="who-we-are-main-content-second-component-text">
                    {item?.title === strings.who_we_are_option_four ? (
                      <CoreValuesTexts />
                    ) : (
                      item?.text
                    )}
                  </h3>
                  <div className="manifesto-shape-container">
                    <img
                      src={ManifestoShape}
                      alt="Shape"
                      className="manifesto-shape"
                      draggable="false"
                      loading="lazy"
                    />
                    <img
                      src={item?.image}
                      alt="Selected"
                      className="selected-item-image"
                      draggable="false"
                      loading="lazy"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <a
          className="download-manifesto-container"
          href={strings.manifesto_link}
          target="_blank"
          rel="noopener noreferrer"
          data-aos="animation-scale-y"
          data-aos-delay="0"
          data-aos-offset="0"
        >
          <span>{strings.download_manifesto_text}</span>
        </a>
      </div>
    </div>
  );
};
export default WhoWeAre;
